<template>
  <component
    :is="contents.id ? 'a' : 'div'"
    :href="
      !isBrewing && contents.id
        ? $customUrlScheme.page('detail', `url=/pro/detail/${contents.id}`)
        : false
    "
    v-ripple="$utilities.getRippleOptions('light')"
    class="dp-pro-recipe-profile">
    <div class="dp-pro-recipe-profile__avatar">
      <ProAvatar
        :src="contents.image_url"
        size="48"
        :alt="contents.name"
        :is-bordered="false" />
    </div>

    <p class="dp-pro-recipe-profile__name">{{ contents.name }}</p>
    <span class="dp-pro-recipe-profile__romanized-name">{{
      contents.name_alphabetical
    }}</span>

    <div v-if="!isBrewing" class="dp-pro-recipe-profile__icons">
      <Icon name="dpProR" color="dpBlack01" :width="22" :height="22" />
      <Icon name="dpArrowRightB" color="dpBlack01" :width="10" :height="10" />
    </div>

    <p class="dp-pro-recipe-profile__overview">{{ contents.overview }}</p>
  </component>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    contents: {
      type: Object,
      default: null
    }
  },

  setup(props, context) {
    // 抽出中か
    const isBrewing = computed(
      () => context.root.$route.name === 'DripPodProRecipeBrewing'
    );

    return {
      isBrewing
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe-profile {
  display: grid;
  gap: 4px 8px;
  align-items: center;
  justify-content: space-between;
  grid-auto-columns: 48px 1fr auto;
  margin-bottom: 16px;
  text-decoration: none;
  color: inherit;

  &:last-child {
    margin-bottom: 0;
  }

  > * {
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__avatar {
    grid-row: 1 / 3;
    grid-column: 1;
  }

  &__name {
    grid-row: 1;
    grid-column: 2;
    place-self: end start;
    margin-bottom: 0;
    font-weight: bold;
    line-height: 1.3;
  }

  &__romanized-name {
    grid-row: 2;
    grid-column: 2;
    place-self: start;
    color: variables.$dpGrey88;
    font-size: 12px;
    line-height: 1.1;
  }

  &__icons {
    display: grid;
    place-items: center end;
    grid-row: 1 / 3;
    grid-column: 3;
    gap: 14px;
    grid-template-columns: 22px 12px;
    padding-right: 4px;
  }

  &__overview {
    grid-column: 1 / span 3;
    margin-bottom: 0;
    padding-top: 12px;
    white-space: pre-wrap;
  }
}
</style>
