var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.contents.id ? "a" : "div",
    {
      directives: [
        {
          name: "ripple",
          rawName: "v-ripple",
          value: _vm.$utilities.getRippleOptions("light"),
          expression: "$utilities.getRippleOptions('light')",
        },
      ],
      tag: "component",
      staticClass: "dp-pro-recipe-profile",
      attrs: {
        href:
          !_vm.isBrewing && _vm.contents.id
            ? _vm.$customUrlScheme.page(
                "detail",
                "url=/pro/detail/" + _vm.contents.id
              )
            : false,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dp-pro-recipe-profile__avatar" },
        [
          _c("ProAvatar", {
            attrs: {
              src: _vm.contents.image_url,
              size: "48",
              alt: _vm.contents.name,
              "is-bordered": false,
            },
          }),
        ],
        1
      ),
      _c("p", { staticClass: "dp-pro-recipe-profile__name" }, [
        _vm._v(_vm._s(_vm.contents.name)),
      ]),
      _c("span", { staticClass: "dp-pro-recipe-profile__romanized-name" }, [
        _vm._v(_vm._s(_vm.contents.name_alphabetical)),
      ]),
      !_vm.isBrewing
        ? _c(
            "div",
            { staticClass: "dp-pro-recipe-profile__icons" },
            [
              _c("Icon", {
                attrs: {
                  name: "dpProR",
                  color: "dpBlack01",
                  width: 22,
                  height: 22,
                },
              }),
              _c("Icon", {
                attrs: {
                  name: "dpArrowRightB",
                  color: "dpBlack01",
                  width: 10,
                  height: 10,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("p", { staticClass: "dp-pro-recipe-profile__overview" }, [
        _vm._v(_vm._s(_vm.contents.overview)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }